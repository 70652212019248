import { Component } from 'react';
import { setHeaders } from '@appbaseio/reactivecore/lib/actions';
import types from '@appbaseio/reactivecore/lib/utils/types';
import { isEqual } from '@appbaseio/reactivecore/lib/utils/helper';

import Base from '@appbaseio/reactivesearch/lib/styles/Base';
import { connect } from '@appbaseio/reactivesearch/lib/utils';

import URLSearchParams from '@ungap/url-search-params'

class SkrSearchFunnel extends Component {
  constructor(props) {
    super(props)
    this.params = new URLSearchParams();
  }

  componentWillReceiveProps(nextProps) {
    let paramsChanged = false;

    if (!isEqual(this.props.selectedValues, nextProps.selectedValues)) {
      const currentComponents = Object.keys(nextProps.selectedValues)
      currentComponents.filter(component => nextProps.components[component].searchFunnel || false).forEach((component) => {
        this.setValue(component, this.getValue(nextProps.selectedValues[component].value));
        paramsChanged = true;
      });

      // remove unmounted components
      Object.keys(this.props.selectedValues).
        filter(component => !currentComponents.includes(component)).
        forEach((component) => {
          this.params.delete(component);
          paramsChanged = true;
        });

      if (!currentComponents.length) {
        Array.from(this.params.keys()).forEach((item) => {
          this.params.delete(item);
          paramsChanged = true;
        });
      }
    }
    // console.log(paramsChanged, this.props.transitionOnParamChange)
    if (paramsChanged && this.props.transitionOnParamChange && typeof window !== 'undefined') {
      this.transition();
    }
  }

  setValue(component, value) {
    if (!value || (typeof value === 'string' && value.trim() === '') || (Array.isArray(value) && value.length === 0)) {
      this.params.delete(component);
    } else {
      const data = JSON.stringify(this.getValue(value));
      if (data !== this.params.get(component)) {
        this.params.set(component, data);
      }
    }
  }

  getValue(value) {
    if (Array.isArray(value) && value.length) {
      return value.map(item => this.getValue(item));
    } else if (value && typeof value === 'object') {
      // TODO: support for NestedList
      if (value.location) return value;
      return value.label || value.key || null;
    }
    return value;
  }

  transition() {
    const paramsString = this.params.toString() ? this.params.toString() : ''
    window.location.href = `${this.props.searchUrl}?${paramsString}`
  }

  render() {
    return (
      <Base style={this.props.style} className={this.props.className}>
        {!this.props.transitionOnParamChange ? (<button className="button is-expanded" onClick={this.transition.bind(this)}>{this.props.transitionButtonText}</button>) : null}
      </Base>
    );
  }
}

SkrSearchFunnel.propTypes = {
  selectedValues: types.selectedValues,
  // component props
  children: types.children,
  style: types.style,
  className: types.string,
  searchUrl: types.stringRequired,
  components: types.params,
  transitionOnParamChange: types.bool,
  transitionButtonText: types.string
};

SkrSearchFunnel.defaultProps = {
  style: {},
  className: null,
  transitionOnParamChange: false,
  transitionButtonText: 'Reise finden!'
};

const mapStateToProps = state => ({
  selectedValues: state.selectedValues,
});

const mapDispatchtoProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchtoProps)(SkrSearchFunnel);
